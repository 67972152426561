<template>
  <div class="container-fluid pt-3 pl-4 pr-4">
    <div>
      <table class="table table-striped table-hover">
        <thead class="thead-dark">
        <tr>
          <th v-for="field in tableFields" :key="field.key" @click="sortTable(field.key)" style="cursor:pointer">
            {{ field.label }}
            <span v-if="currentSort.field === field.key">
              <i v-if="currentSort.order === 'asc'" class="bi bi-arrow-down"></i>
              <i v-else class="bi bi-arrow-up"></i>
            </span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in sortedItems" :key="item.id">
          <td>{{ item.id }}</td>
          <td>{{ item.title }}</td>
          <td>{{ item.category.name }}</td>
          <td>{{ item.created_by.name }}</td>
          <td>{{ item.impressions }}</td>
          <td>{{ item.created_at_relative }}</td>
          <td>{{ item.updated_at_relative }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import Statistic from '@/components/Statistic'
  import request from '@/request/index'
  import consti from '../const'

  export default {
    name: "Dashboard",
    components: {
      Statistic
    },
    created() {
      this.fetchData()
    },
    mounted() {
      document.title = 'BMS | Dashboard'
    },
    computed: {
      sortedItems() {
        return this.tableItems.sort((a, b) => {
          if (this.currentSort.field === '') return 0;
          if (this.currentSort.order === 'asc') {
            return a[this.currentSort.field] < b[this.currentSort.field] ? -1 : 1;
          } else {
            return a[this.currentSort.field] > b[this.currentSort.field] ? -1 : 1;
          }
        });
      }
    },
    data() {
      return {
        tableFields: [
          { key: 'id', label: '#' },
          { key: 'title', label: 'Title' },
          { key: 'category', label: 'Category' },
          { key: 'created_by', label: 'Created By' },
          { key: 'impressions', label: 'Clicks' },
          { key: 'created_at', label: 'Published' },
          { key: 'updated_at_relative', label: 'Last Updated' }
        ],
        tableItems: [],
        currentSort: {
          field: '',
          order: 'asc'
        }
      };
    },
    methods: {
      sortTable(field) {
        if (this.currentSort.field === field) {
          this.currentSort.order = this.currentSort.order === 'asc' ? 'desc' : 'asc';
        } else {
          this.currentSort.field = field;
          this.currentSort.order = 'asc';
        }
      },
      fetchData() {
        request.get(consti.baseUrl + consti.stats, this.$router)
          .then(data => {
            this.loading = false
            this.tableItems = data
          }, error => {
            this.loading = false
          })
      }
    }
  }
</script>

<style scoped>

</style>
